




import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  GET_USER_DETAILS,
  NAVIGATE_USER_BASED_ON_ROLE,
  REHYDRATE_STATE,
  UPDATE_USER_COMPANY
} from "@/store/modules/auth/constants";
import { ROOT_ERROR } from "@/store/modules/root/constants";
import router from "@/router";

export default Vue.extend({
  name: "SocialAuth",
  beforeRouteEnter(to, from, next) {
    const param_id = to.params.token;
    if (!param_id) next(false);
    else next();
  },
  beforeRouteUpdate(to, from, next) {
    const param_id = to.params.token;
    if (!param_id) next(false);
    else next();
  },
  computed: {
    ...mapGetters("auth", {
      user_details: GET_USER_DETAILS
    })
  },
  async created() {
    const param = this.$route.params.token;
    if (param) {
      const token = param.toString();
      localStorage.setItem("token", token);
      if (await this.init_user()) {
        // const res = await this.update_company({
        //   email: this.user_details.email
        // });
        // if (!res) {
        //   this.set_token("");
        //   localStorage.removeItem("token");
        //   this.root_error("Internal Error. Please try against");
        //   await router.push("/");
        //   return;
        // }
        await this.navigation();
      } else {
        localStorage.removeItem("token");
        this.root_error("Internal Error. Please try against");
        await router.push("/");
      }
    } else {
      localStorage.removeItem("token");
      this.root_error("Internal Error. Please try against");
      await router.push("/");
    }
  },
  methods: {
    ...mapMutations({
      root_error: ROOT_ERROR
    }),
    ...mapActions("auth", {
      init_user: REHYDRATE_STATE,
      navigation: NAVIGATE_USER_BASED_ON_ROLE,
      update_company: UPDATE_USER_COMPANY
    })
  }
});
